const LEVEL = "preprod";

const commons = {
    iva : 22,
    paymentMethods : [
        { text : "Pagamento online", value : "online" },
        { text : "Pagamento in palestra", value : "manual" },
    ],
    roles : [
        { text : "Utente", value : "user" },
        { text : "Staff Viewer", value : "viewer" },
        { text : "Editor", value : "editor" },
        { text : "Admin", value : "admin" },
    ],
    mediatypes : [
        { text : "Icona", value : "icon" },
        { text : "Copertina (Immagine)", value : "cover" },
        { text : "Banner", value : "banner" },
        { text : "Galleria", value : "gallery" },
        { text : "Audio", value : "audio" },
        { text : "Video", value : "video" },
    ],
    sectionTypes : [
        { text : "Custom", value : "custom" },
        { text : "Genere", value : "genre" },
        { text : "Categoria", value : "category" },
        { text : "Allenamenti in corso", value : "resume" },
        { text : "Pacchetti in possesso", value : "mykits" },
    ],
    cartStatusOptions : [
        { text : "Ordine aperto", value : "draft" },
        { text : "Ordine pagato (in attesa di spedizione)", value : "paid" },
        { text : "Ordine chiuso", value : "closed" },
    ],
};

const dev = {
    debug : true,
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'http://localhost:4242',
};
const preprod = {
    debug : true,
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'https://devapi.topupgym.pro',
};
const prod = {
    debug : false,
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'https://api.topupgym.pro',
};

let config;

switch (LEVEL) {
    case "prod":
        config = Object.assign(commons, prod);
        break;
    case "preprod":
        config = Object.assign(commons, preprod);
        break;
    case "dev":
    default:
        config = Object.assign(commons, dev);
        break;
}

export default config;